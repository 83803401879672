<script>
import LogoCedula from "../assets/icons/cedula.png";
import LogoSelfie from "../assets/icons/selfie.png";
// import { MglMap, MglMarker, MglNavigationControl } from "vue-mapbox";
import Identificacion from "../components/Identificacion.vue";
import Pasos from "../components/Pasos.vue";
import General from "../components/General.vue"
import Dinamico from "../components/Dinamico.vue"
import Terminos from '../components/Terminos.vue'
import $ from "jquery";
export default {
  name: "Reconocimiento",
  components: {
    Pasos,
    Identificacion,
    General,
    Dinamico,
    Terminos
    // MglMap,
    // MglMarker,
    // MglNavigationControl,
  },
  props: ["APIS", "header", "copyright", "social", "title"],

  data: function () {
    return {
      estado: "",
      selfieError: false,
      modalmessage: "",
      msgError: "Datos incorrectos intente de nuevo",
      coordinates: [-78.183406, -1.831239], //-78.183406,-1.831239
      email_user: this.$session.get("email"),
      dni: this.$session.get("dni"),
      tlf_user: "",
      tlf_user_text: "",
      sendCodeStatus: false,
      numbersteps: 1,
      card1: true,
      card2: true,
      imagenCedula: null,
      imagenSelfie: null,
      elemetsForm: [],
      elemetsTerm: [],
      dataFormOnly: [],
      dataForm: [],
      dataTerm: [],
      dataCode: [],
      img1: LogoCedula,
      img2: LogoSelfie,
      msj1: "Toma una fotografía del Frente y Revés de tu cédula",
      msj2: "Click aquí para capturar una selfie con tu dispositivo",
      msjuno: "Toma una fotografía del Frente y Revés de tu cédula",
      msjdos: "Click aquí para capturar una selfie con tu dispositivo",
      msj3: "+ Subir Foto",
      msj4: "Activar Camara",
      rutaDestino: "/",
      rutaInicio: "/",
      rutaRegistro: "/registro",
      Terms: "Terminos Y Condiciones dependiente de cada Entidad ",
      center: { lat: 39.7837304, lng: -100.4458825 },
    };
  },
  mounted: function () {
    this.geolocate();
    if (this.$session.exists()) {
      if (this.$session.get("number") == 1) {
        location.reload();
      }
      this.$session.remove("number");
      this.coordinates = [this.header.longitud, this.header.latitud];
      $("html, body").animate({ scrollTop: $("#footer").offset() }, 2000);
    } else {
      // this.$router.push("/");
    }
  },
  methods: {
    successFace: function () {
      this.imagenSelfie = 'holi';
      this.numbersteps = 2;
    },
    successGeneral: function (dataForm, dataDinami) {
      if (this.elemetsForm.length == 0) {
        dataDinami.forEach((element) => {
          this.elemetsForm.push(element);
        });
      }
      console.log(dataForm)
      this.tlf_user = dataForm.tlf_user;
      this.dataFormOnly = dataForm;
      this.tlf_user_text = 593 + this.tlf_user.substr(1, 1) + "xxxxxx" + this.tlf_user.substr(-1);
      this.numbersteps = 3;
    },
    successDinamico: function (dataForm, dataTerm) {
      if (this.elemetsTerm.length == 0) {
        dataTerm.forEach((element) => {
          this.elemetsTerm.push(element);
        });
      }
      this.dataForm = dataForm;
      this.numbersteps = 4;
    },
    successTerminos: function (dataForm) {
      this.dataTerm = dataForm;
      this.numbersteps = 5;
      this.sendCodeStatus = true;
    },
    geolocate: function () {
      navigator.geolocation.getCurrentPosition(position => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        }
      })
    },
    closeModal() {
      this.$modal.hide("modalError");
      // if (this.numbersteps == 1) {
      //   location.href = this.rutaRegistro;
      // }
    },


    submitCode: function () {
      const formData = new FormData(this.$refs["code-data"]);
      const data = {}; // need to convert it before using not with XMLHttpRequest
      for (let [key, val] of formData.entries()) {
        Object.assign(data, { [key]: val });
      }
      this.dataCode = data;
      const dataFinal = [];
      dataFinal.push(
        {
          civilPhoto: this.$session.get("civilPhoto"),
          imagenSelfie: this.$session.get("imagenSelfie")
        }
      );
      dataFinal.push(this.dataFormOnly);
      dataFinal.push(this.dataForm);

      // dataFinal.push(this.dataTerm);
      let code = "";
      let tlf_user = this.tlf_user;
      if (tlf_user.length == 10) {
        tlf_user = this.tlf_user.substr(1);
      }
      for (let i = 1; i < 7; i++) {
        code += $("#digit" + i).val();
      }
      dataFinal.push(data);

      const headers = {
        lat: this.header.latitud,
        long: this.header.longitud,
        Authorization: "Bearer " + this.header.token,
      };
      const body = {
        id_entity: this.header.idEntity,
        email_user: this.email_user,
        dni: this.dni,
        tlf_user: "593" + tlf_user,
        transaction_type: "Apertura de cuenta",
        code: code,
      };

      this.$http
        .post(this.APIS[7].name, body, { headers })
        .then((response) => {
          if (response.data.status == true) {
            const headers = {
              lat: this.header.latitud,
              long: this.header.longitud,
              Authorization: "Bearer " + this.header.token,
            };
            const body = {
              id_entity: this.header.idEntity,
              data: dataFinal,
              email_user: this.email_user,
              dni: this.dni,
              tlf_user: "593" + tlf_user,
            };

            this.$http
              .post(this.APIS[5].name, body, { headers })
              .then((response) => {
                if (response.status == 200) {
                  //this.$router.push('/registrado')
                  window.location.href = "/registrado";
                } else {
                  //this.$router.push('/registrado')
                  window.location.href = "/registrado";
                }
              })
              .catch((error) => {
                if (error) {
                  this.msgError == "Error de Red";
                  this.$modal.show("modalError");
                }
              });
          } else {
            this.msgError = response.data.message;
            this.$modal.show("modalError");
            if (response.data.reply) {
              this.$modal.show("sendCode");
            }
            //  this.msgError="Datos incorrectos intente de nuevo";
          }
        })
        .catch((error) => {
          if (error) {
            this.$modal.show("modalError");
          }
        });
    },
    sendCode() {
      let tlf_user = $("#sendcode").val();
      this.tlf_user = tlf_user;
      this.tlf_user_text =
        593 + this.tlf_user.substr(1, 1) + "xxxxx" + this.tlf_user.substr(-1);
      this.tlf_user = tlf_user;
      if (tlf_user.length == 10) {
        tlf_user = this.tlf_user.substr(1);
        const headers = {
          lat: this.header.latitud,
          long: this.header.longitud,
          Authorization: "Bearer " + this.header.token,
        };
        const body = {
          id_entity: this.header.idEntity,
          email_user: this.email_user,
          dni: this.dni,
          tlf_user: "593" + tlf_user,
          transaction_type: "Apertura de cuenta",
        };

        this.$http
          .post(this.APIS[6].name, body, { headers })
          .then((response) => {
            if (response.status == 200) {
              this.$modal.hide("sendCode");
            } else {
              this.$modal.show("modalError");
            }
          })
          .catch((error) => {
            console.error(error);
            this.$modal.show("modalError");
          });
      } else {
        this.msgError = "Ingrese un número de Celular válido";
        this.$modal.show("modalError");
      }
    },

    regresar: function (variable) {
      switch (variable) {
        case 1:
          this.$session.clear();
          this.$session.destroy();
          this.$router.push("/");
          break;
        case 2:
          this.$session.clear();
          this.$session.destroy();
          window.location.href = "/";
          break;
        case 3:
          this.$session.clear();
          this.$session.destroy();
          window.location.href = "/";
          break;
        case 4:
          this.numbersteps = 3;
          break;
        case 5:
          this.numbersteps = 4;
      }
    },
    updateData() {
      switch (this.numbersteps) {
        case 2:
          $("#firstform").click();
          break;
        case 3:
          $("#Secondform").click();
          break;
        case 4:
          $("#threeform").click();
          break;
        case 5:
          $("#fourthform").click();
          break;
      }
    },

  },
};
</script>

<template>
  <div class="container">
    <!-- <div class="slider">
                            <ul>
                              <li><img src="../assets/images/carr_1.png" /></li>
                              <li><img src="../assets/images/carr_2.png" /></li>
                              <li><img src="../assets/images/carr_3.png" /></li>
                              <li><img src="../assets/images/carr_4.png" /></li>
                            </ul>
                          </div> -->
    <Pasos :step="numbersteps" />


    <div class="container-registers">
      <div>
        <div v-show="numbersteps == 1">
          <img class="step" src="../assets/icons/paso_1.png" />
          <p class="steps-descriptions">
            Por su seguridad, comprobaremos su identidad
          </p>
        </div>
        <div v-show="numbersteps == 2">
          <img class="step" src="../assets/icons/paso_2.png" />
          <p class="steps-descriptions">
            Recuerda que al información que has proporcionado, es confidencial.
          </p>
        </div>
        <div v-show="numbersteps == 3">
          <img class="step" src="../assets/icons/paso_3.png" />
          <p class="steps-descriptions">
            Recuerda que al información que has proporcionado, es confidencial.
          </p>
        </div>
        <div v-show="numbersteps == 4">
          <img class="step" src="../assets/icons/paso_4.png" />
          <p class="steps-descriptions">
            Recuerda que al información que has proporcionado, es confidencial.
          </p>
        </div>
        <div v-show="numbersteps == 5">
          <img class="step5" src="../assets/icons/paso_5.png" />
          <p class="steps-descriptions">
            Ingresa el código que te enviamos al {{ this.tlf_user_text }} espera 3
            min para recibirlo
          </p>
          <!--  <span id="minutes"></span> minutos - <span id="seconds"></span> segundos para ingresarlo</p>
                                   <button @click="accountant()">aqui</button>-->
        </div>
      </div>


      <Identificacion v-show="numbersteps == 1" :header="this.header" :APIS="this.APIS" @successApi="successFace" />

      <General v-if="numbersteps == 2" :header="this.header" :APIS="this.APIS" @successApi="successGeneral" />

      <Dinamico v-if="numbersteps == 3" :header="this.header" :APIS="this.APIS" :form="elemetsForm"
        @successApi="successDinamico" />
      <Terminos v-if="numbersteps == 4" :header="this.header" :APIS="this.APIS" :form="elemetsTerm"
        :telefono="this.tlf_user" @successApi="successTerminos" :dni="this.dni" :email="this.email_user" />
      <div v-show="numbersteps == 5" class="container-data-personal">
        <form ref="code-data" id="code-data" @submit.prevent="submitCode">
          <input class="code" maxlength="1" onkeyup="if (this.value.length == 1) digit2.focus()" name="digit1" id="digit1"
            type="text" required />
          <input class="code" maxlength="1" onkeyup="if (this.value.length == 1){digit3.focus()}else{digit1.focus()}"
            name="digit2" id="digit2" type="text" required />
          <input class="code" maxlength="1" onkeyup="if (this.value.length == 1){digit4.focus()}else{digit2.focus()}"
            name="digit3" id="digit3" type="text" required />
          <input class="code" maxlength="1" onkeyup="if (this.value.length == 1){digit5.focus()}else{digit3.focus()}"
            name="digit4" id="digit4" type="text" required />
          <input class="code" maxlength="1" onkeyup="if (this.value.length == 1){digit6.focus()}else{digit4.focus()}"
            name="digit5" id="digit5" type="text" required />
          <input class="code" maxlength="1" onkeyup="if (this.value.length == 0) digit5.focus()" name="digit6" id="digit6"
            type="text" required />
          <input hidden type="submit" id="fourthform" />
        </form>
        <a href="#code-data" @click="$modal.show('sendCode')">No me llega el código</a>
        <p class="warning">
          Al confirmar firmas electrónicamente, de acuerdo con la ley, el
          convenio de relación comercial, contrato de cuenta y Formulario de
          Residencia Fiscal con {{ this.title }} con plena validez y efectos
          jurídicos, sin necesidad de rectificación o solemnidad posterior.
        </p>
        <br />
        <br />
      </div>

      <div style="display: flex">
        <input v-show="this.sendCodeStatus != true" class="form-submit create btnRegister" type="button"
          v-on:click="regresar(numbersteps)"
          :value="this.numbersteps == 2 || this.numbersteps == 3 ? 'Cancelar' : 'Regresar'" />
        <input v-show="this.imagenSelfie != null" class="form-submit continue btnRegister" type="button"
          @click="updateData" value="Continuar" />
      </div>

      <modal name="modalmessage" :draggable="false" :scrollable="false" width="40%" height="auto" :resizable="false"
        :adaptive="false">
        <center class="modal-alert">
          {{ modalmessage }}
          <input @click="$modal.hide('modalmessage')" class="form-submit alert" value="Continuar" />
        </center>
      </modal>
      <modal name="sendCode" :draggable="false" :scrollable="false" width="40%" height="auto" :resizable="false"
        :adaptive="false">
        <center class="modal-sendcode">
          Código enviado a
          <input class="number" id="sendcode" name="sendcode" type="number"
            onkeypress="return event.charCode >= 48 && event.charCode <= 57" :value="this.tlf_user" />
          <p>Puede modificar su número de celular</p>
          <br />
          <input @click="sendCode" class="form-submit alert" value="Enviar Código de nuevo" />
        </center>
      </modal>
      <modal id="modalError" name="modalError" :draggable="false" :scrollable="false" width="40%" height="auto"
        :resizable="false" :adaptive="false">
        <center class="modal-alert">
          {{ msgError }}
          <input @click="closeModal" class="form-submit alert" value="Entendido" />
        </center>
      </modal>
    </div>
    <div class="footer-create-account" id="footer">
      <p>{{ this.copyright }}</p>
      <a :href="this.social.facebookPage" target="_blank">
        <i class="fa fa-facebook-official" aria-hidden="true"></i></a>
      <a :href="this.social.twitterPage" target="_blank"><i class="fa fa-twitter-square" aria-hidden="true"></i></a>
      <a :href="this.social.instagramPage" target="_blank"><i class="fa fa-instagram" aria-hidden="true"></i></a>
      <a :href="this.social.youtubePage" target="_blank"><i class="fa fa-youtube-play" aria-hidden="true"></i></a>
    </div>
  </div>
</template>

<style scoped>
.icons-targets {
  width: 14%;
  position: absolute;
  margin: -6vw 0vw 1vw 38vw;
  background: #f7f7f7 0% 0% no-repeat padding-box;
  box-shadow: 6px 6px 6px #00000029;
  border-radius: 12px;
  opacity: 1;
}

.icons-targets div {
  display: flex;
  margin: 1vw;
  cursor: pointer;
}

.icons-targets div:hover {
  background: #e0dfdf;
}

.icons-targets img {
  width: 3vw;
}

.icons-targets p {
  margin: auto;
}

.slider {
  width: 100%;
  margin: auto;
  overflow: hidden;
}

.slider ul {
  display: flex;
  padding: 0;
  width: 400%;
  animation: cambio 20s infinite alternate linear;
}

.slider li {
  width: 100%;
  list-style: none;
  margin: auto;
  display: flex;
}

.slider img {
  width: 75%;
  height: 8vw;
  margin: auto;
  border-radius: 1vw;
}

@keyframes cambio {
  0% {
    margin-left: 0;
  }

  20% {
    margin-left: 0;
  }

  25% {
    margin-left: -100%;
  }

  45% {
    margin-left: -100%;
  }

  50% {
    margin-left: -200%;
  }

  70% {
    margin-left: -200%;
  }

  75% {
    margin-left: -300%;
  }

  100% {
    margin-left: -300%;
  }
}

.alert {
  width: 32%;
  text-align: center;
  margin-top: 1vw;
}

.modal-alert {
  color: var(--colorP);
  font-size: 1.5vw;
  padding: 3vw 1vw;
}

.modal-sendcode {
  color: var(--colorP);
  font-size: 1.5vw;
  padding: 3vw 1vw;
}

.modal-sendcode .number {
  font-size: 1.3vw;
  width: 27%;
}

.modal-sendcode p {
  font-size: 1.1vw;
}




.vm--modal {
  overflow: auto !important;
}



.step {
  width: 65vw;
}

.step5 {
  width: 65vw;
}

.warning {
  font-size: 1vw;
}

.code {
  height: 6vw;
  width: 6vw;
  border-radius: 9px;
  margin: 3vw 1vw;
  border: solid;
  border-color: gray;
  font-size: 6vw;
  text-align: center;
}



#map {
  width: 92%;
  height: 355px;
  margin: 3vw 3vw 4vw 1vw;
}

.container {
  background-image: linear-gradient(45deg, #ffffff, #ffffffad),
    url(../assets/images/fondo_2.png);
}

.create {
  width: 15%;
  margin: auto;
}

.continue {
  width: 15%;
  margin: auto;
}


.steps-descriptions {
  margin: 0vw 0vw 0vw 2vw;
}

.form-data-personal {
  display: inline;
}

.param-data-personal {
  display: inline-grid;
  width: 40%;
  margin: 1vw 7vw 0vw 1vw;
}

.param-data-personal input,
select {
  width: 100%;
  margin: 1vw 0.2vw;
  height: 2.5vw;
  border-radius: 6px;
  border-color: #31353552;
  padding: 0px 0px 0px 14px;
  border-style: solid;
  font-size: 1vw;
}

#data-personal {
  margin-top: 2vw;
}

.param-data-personal select {
  width: 104% !important;
  border-style: solid;
  border-width: 0.15vw;
}

.param-data-personal input:focus {
  outline: 0;
  border-color: var(--colorP);
}

.param-data-personal label {
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  #map {
    margin: 3vw 3vw 10vw 1vw;
  }

  .icons-targets {
    width: 45%;
    margin: 2vw 1vw 3vw 20vw;
    border-radius: 6px;
    opacity: 1;
    position: inherit;
  }

  .icons-targets div {
    margin: 2vw;
  }

  .icons-targets img {
    width: 8vw;
  }

  .icons-targets p {
    margin: auto;
  }

  .btnRegister {
    width: 40%;
    height: 14vw;
    font-size: 5vw;
  }

  .modal-sendcode {
    font-size: 5.5vw;
  }

  .modal-sendcode input {
    font-size: 5.3vw;
    width: 97% !important;
    margin: 4vw 0vw;
  }

  .modal-sendcode .number {
    font-size: 5.3vw !important;
    width: 83% !important;
    margin: 4vw 0;
  }

  .modal-sendcode p {
    font-size: 4.1vw;
  }

  .step {
    width: 83vw;
  }

  .step5 {
    width: 85vw;
  }

  .param-data-personal {
    width: 100%;
  }

  .param-data-personal input,
  select {
    height: 45px;
    border-radius: 8px;
    margin-bottom: 4vw;
    font-size: 4vw;

  }

  .code {
    height: 10vw;
    width: 10vw;
  }

  .warning {
    font-size: 2vw;
  }



  .card canvas {
    width: 59vw !important;
    height: 32vw !important;
    margin: auto !important;
    float: right !important;
  }

}


.card canvas {
  width: 12vw;
  height: 9vw;
  display: flex;
  margin: 3vw auto auto auto;
  float: right;
}




.container-registers {
  width: 84%;
  margin: auto;
  width: 84%;
  margin: auto;
  padding: 0vw 0vw 7vw 0px;
}

.title-steps {
  color: black;
  font-weight: bolder;
  font-size: 2.5vw;
  margin-bottom: 1vw;
}

.img-nav {
  width: 100%;
}

.cambios {
  color: red;
}

.icon-nav {
  width: 15vw !important;
  margin-left: 20vw;
}

#footer {
  border-top-color: none;
}

#footer p {
  color: black;
}

#footer i {
  color: black;
}
</style>



<script>
import $ from "jquery";
import ubicacion from "../constantes/ubicacion.json"
export default {
    props: ['header', 'APIS', 'form'],
    data: function () {
        return {
            ubicaciones: ubicacion,
            coordinates: [-78.183406, -1.831239],
            center: { lat: 39.7837304, lng: -100.4458825 },
            citys: null,
            parroquis: null,
            province: "",
            city: "",
            parroqui: "",
        }
    },
    methods: {
        validate: function (a, b) {
            var e = $("#" + a).val();
            var reg = /^\d+$/;
            if (b == 1) {
                if (!reg.test(e)) {
                    $("#" + a).val("");
                }
            } else {
                var numeros = "0123456789";
                for (var i = 0; i < e.length; i++) {
                    if (numeros.indexOf(e.charAt(i), 0) != -1) {
                        $("#" + a).val("");
                    }
                }
            }
        },
        coordStreetGoogle(component) {
            this.coordinates = [component.latLng.lng(), component.latLng.lat()];
        },
        submitForm: function () {
            const formData = new FormData(this.$refs["data-personal2"]); // reference to form element
            const data = {}; // need to convert it before using not with XMLHttpRequest
            for (let [key, val] of formData.entries()) {
                Object.assign(data, { [key]: val });
            }
            Object.assign(data, { ["provincia_txt"]: this.ubicaciones[this.province].provincia });
            Object.assign(data, { ["ciudad_txt"]: this.ubicaciones[this.province].cantones[this.city].canton });
            const headers = {
                lat: this.header.latitud,
                long: this.header.longitud,
                Authorization: "Bearer " + this.header.token,
            };
            const body = {
                id_entity: this.header.idEntity,
                email_user: this.email_user,
                dni: this.dni,
            };
            //URL DE OBTENCIÓN DE TERMINOS
            this.elemetsTerm = [];
            this.$http.post(this.APIS[2].name, body, { headers })
                .then((response) => {
                    if (response.status == 200) {

                        this.numbersteps = 4;
                        this.$emit("successApi", data, response.data);
                        $("html, body").animate(
                            { scrollTop: $("#navs").offset().top },
                            2000
                        );
                    } else {
                        this.$modal.show("modalError");
                    }
                })
                .catch((error) => {
                    console.error(error);
                    this.$modal.show("modalError");
                });
        },
        changeProvince() {
            //const now = this.ubicaciones.find(element => element.provincia == this.province);
            //var as = JSON.parse(this.ubicaciones);
            this.city = "";
            this.parroqui = ""
            this.citys = this.ubicaciones[this.province].cantones;

        },
        changeCity() {
            this.parroqui = ""
            this.parroquis = this.citys[this.city].parroquias;
        },

    }
};
</script>
<template>
    <div class="container-data-personal">
        <form ref="data-personal2" id="data-personal2" @submit.prevent="submitForm">
            <br />
            <div v-for="item in form" v-bind:key="item.id" class="param-data-personal">
                <label :for="item.key">{{ item.nombre }}</label>
                <!--v-on:keyup="validate(item.identificador, item.validador)" -->
                <input v-if="item.tipo != 'combobox'" :name="item.identificador"
                    onkeyup="this.value = this.value.toUpperCase();" :type="item.tipo" :id="item.identificador"
                    onpaste="return false" :max="item.max" :required="item.requerido" :placeholder="item.placeholder" />

                <div v-else>
                    <select :name="item.identificador" :id="item.identificador" :required="item.requerido"
                        :placeholder="item.placeholder">
                        <option value="" selected disabled>
                            {{ item.placeholder }}
                        </option>
                        <option v-for="datacombo in item.datacombo" v-bind:key="datacombo.id" :value="datacombo.valor">
                            {{ datacombo.nombre }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="param-data-personal">
                <label>Provincia</label>
                <select v-model="province" name="province" id="province" @change="changeProvince()" required
                    placeholder="item.placeholder">
                    <option value="" selected disabled>Seleccione Provincia</option>
                    <option v-for=" (item, index) in this.ubicaciones" v-bind:key="index" :value="index">

                        {{ item.provincia }}
                    </option>

                </select>
            </div>
            <div class="param-data-personal">
                <label>Canton</label>
                <select v-model="city" name="city" id="city" :disabled="this.province == ''" required @change="changeCity()"
                    placeholder="item.placeholder">

                    <option value="" selected disabled>Seleccione Ciudad</option>
                    <option v-for=" (item, index) in this.citys" v-bind:key="item.canton" :value="index">{{ item.canton }}
                    </option>

                </select>
            </div>
            <br />
            <br />
            <h4>Datos de una persona o familiar para dejar como representante de la cuenta
                en caso de fallecimiento del titular
            </h4>
            <br />
            <div class="param-data-personal">
                <label>Nombres y Apellidos Completos</label>
                <!-- @keydown="sololetras(event);"-->
                <input type="text" name="nombres_familiar" id="nombres_familiar"
                    onkeyup="this.value = this.value.toUpperCase();" required />
            </div>
            <div class="param-data-personal">
                <label>Correo electrónico</label>
                <!-- @keydown="sololetras(event);"-->
                <input type="email" name="email_familiar" id="email_familiar" required />
            </div>
            <div class="param-data-personal">
                <label>Celular</label>
                <!-- @keydown="sololetras(event);"-->
                <input type="text" name="movil_familiar" minlength="9" maxlength="10" id="movil_familiar"
                    onkeypress="return event.charCode >= 48 && event.charCode <= 57" required />
            </div>
            <div class="param-data-personal">
                <label>Teléfono fijo (opcional)</label>
                <!-- @keydown="sololetras(event);"-->
                <input type="text" name="tlf_familiar" minlength="9" maxlength="10" id="tlf_familiar"
                    onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
            </div>


            <br />
            <br />
            <input hidden type="submit" id="Secondform" />
        </form>
    </div>
</template>


<style scoped>
#data-personal {
    margin-top: 2vw;
}

.param-data-personal select {
    width: 104% !important;
    border-style: solid;
    border-width: 0.15vw;
}

.param-data-personal input:focus {
    outline: 0;
    border-color: var(--colorP);
}

.param-data-personal label {
    font-weight: bold;
}

.param-data-personal {
    display: inline-grid;
    width: 40%;
    margin: 1vw 7vw 0vw 1vw;
}

.param-data-personal input,
select {
    width: 100%;
    margin: 1vw 0.2vw;
    height: 2.5vw;
    border-radius: 6px;
    border-color: #31353552;
    padding: 0px 0px 0px 14px;
    border-style: solid;
    font-size: 1vw;
}

@media screen and (max-width: 768px) {
    .param-data-personal {
        width: 100%;
    }

    .param-data-personal input,
    select {
        height: 45px;
        border-radius: 8px;
        margin-bottom: 4vw;
        font-size: 4vw;

    }
}
</style>

<script>
import $ from "jquery";
import ubicacion from "../constantes/ubicacion.json"
export default {
    data() {
        return {
            coordinates: [-78.183406, -1.831239],
            center: { lat: 39.7837304, lng: -100.4458825 },
            name_lastname_user: this.$session.get("name"),
            email_user: this.$session.get("email"),
            dni: this.$session.get("dni"),
            fingercode: this.$session.get("fingercode"),
            ubicaciones: ubicacion,
            tlf_user: "",
            province: "",
            city: "",
            parroqui: "",
            state: "",
            nro_house_user: "",
            reference_house_user: "",
            sons: "",
            tlf_user_house: "",
            autocomplete: null,
            currentPlace: null,
            markers: [],
            places: [],
            mapZoom: 14,
            citys: null,
            parroquis: null
        }
    },
    props: ['header', 'APIS', 'title'],
    methods: {
        setPlace(place) {
            this.currentPlace = place;
            if (place) {
                const marker = {
                    lat: this.currentPlace.geometry.location.lat(),
                    lng: this.currentPlace.geometry.location.lng(),
                };
                console.log('add -', marker)
                this.markers.push({ position: marker });
                this.places.push(place);
                this.center = marker;
                this.mapZoom = 18;
                this.currentPlace = null;
            }
        },
        validate: function (a, b) {
            var e = $("#" + a).val();
            var reg = /^\d+$/;
            if (b == 1) {
                if (!reg.test(e)) {
                    $("#" + a).val("");
                }
            } else {
                var numeros = "0123456789";
                for (var i = 0; i < e.length; i++) {
                    if (numeros.indexOf(e.charAt(i), 0) != -1) {
                        $("#" + a).val("");
                    }
                }
            }
        },
        coordStreetGoogle(component) {
            this.coordinates = [component.latLng.lng(), component.latLng.lat()];
        },
        submit: function () {

            const formData = new FormData(this.$refs["data-personal"]); // reference to form element
            const data = {}; // need to convert it before using not with XMLHttpRequest
            for (let [key, val] of formData.entries()) {
                Object.assign(data, { [key]: val });
            }
            Object.assign(data, { ["location"]: this.center });
            Object.assign(data, { ["dni"]: this.dni });
            Object.assign(data, { ["fingercode"]: this.fingercode });
            Object.assign(data, { ["province_txt"]: this.ubicaciones[this.province].provincia });
            Object.assign(data, { ["city_txt"]: this.ubicaciones[this.province].cantones[this.city].canton });

            console.log("DATA DE FORMULARIO GENERAL", data);
            const headers = {
                lat: this.header.latitud,
                long: this.header.longitud,
                dni: this.dni,
                Authorization: "Bearer " + this.header.token,
            };

            //URL DE OBTENCIÓN DE TERMINOS

            const body = {
                id_entity: this.header.idEntity,
                data: data,
                email_user: 'email',
                dni: 'dni',
            };
            this.elemetsForm = [];
            this.$http
                .post(this.APIS[0].name, body, { headers })
                .then((response) => {
                    this.$emit("successApi", data, response.data);
                    $("html, body").animate(
                        { scrollTop: $("#navs").offset().top },
                        2000
                    );
                })
                .catch((error) => {
                    console.error(error);
                    this.$modal.show("modalError");
                });
        },

        changeProvince() {
            //const now = this.ubicaciones.find(element => element.provincia == this.province);
            //var as = JSON.parse(this.ubicaciones);
            this.city = "";
            this.parroqui = ""
            this.citys = this.ubicaciones[this.province].cantones;

        },
        changeCity() {
            this.parroqui = ""
            this.parroquis = this.citys[this.city].parroquias;
        },

    }
};
</script>
<template>
    <div class="container-data-personal">
        <form ref="data-personal" id="data-personal" @submit.prevent="submit">
            <div class="param-data-personal">
                <label>Nombres y Apellidos Completos</label>
                <!-- @keydown="sololetras(event);"-->
                <input v-model="name_lastname_user" type="text" name="name_lastname_user" id="name_lastname_user"
                    v-on:keyup="validate('holi', 0)" required readonly />
            </div>
            <div class="param-data-personal">
                <label>Correo electrónico</label>
                <!-- @keydown="sololetras(event);"-->
                <input v-model="email_user" readonly type="email" name="email_user" id="email_user"
                    v-on:keyup="validate('holi', 0)" required />
            </div>
            <div class="param-data-personal">
                <label>Celular</label>
                <!-- @keydown="sololetras(event);"-->
                <input v-model="tlf_user" type="text" name="tlf_user" minlength="9" maxlength="10" id="tlf_user"
                    onkeypress="return event.charCode >= 48 && event.charCode <= 57" required />
            </div>
            <div class="param-data-personal">
                <label>Teléfono fijo (opcional)</label>
                <!-- @keydown="sololetras(event);"-->
                <input v-model="tlf_user_house" type="text" name="tlf_user_house" maxlength="10" id="tlf_user_house"
                    onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
            </div>
            <div class="param-data-personal">
                <label>Estado civíl</label>
                <select v-model="state" name="state" id="state" required placeholder="item.placeholder">
                    <option value="" selected disabled>Seleccione estado</option>
                    <option value="Soltero/a">Soltero/a</option>
                    <option value="Casado/a">Casado/a</option>
                    <option value="Divorciado/a">Divorciado/a</option>
                    <option value="Viudo/a">Viudo/a</option>
                    <option value="UnionLibre">Unión Libre</option>
                </select>
            </div>
            <div class="param-data-personal">
                <label>Número de cargas familiares</label>
                <input v-model="sons" type="text" name="sons" id="sons"
                    onkeypress="return event.charCode >= 48 && event.charCode <= 57" required />
            </div>

            <div class="param-data-personal">
                <label>Provincia</label>
                <select v-model="province" name="province" id="province" @change="changeProvince()" required
                    placeholder="item.placeholder">
                    <option value="" selected disabled>Seleccione Provincia</option>
                    <option v-for=" (item, index) in this.ubicaciones" v-bind:key="index" :value="index">

                        {{ item.provincia }}
                    </option>

                </select>
            </div>
            <div class="param-data-personal">
                <label>Ciudad</label>
                <select v-model="city" name="city" id="city" :disabled="this.province == ''" required @change="changeCity()"
                    placeholder="item.placeholder">

                    <option value="" selected disabled>Seleccione Ciudad</option>
                    <option v-for=" (item, index) in this.citys" v-bind:key="item.canton" :value="index">{{ item.canton }}
                    </option>

                </select>
            </div>
            <div class="param-data-personal">
                <label>Parroquia</label>
                <select v-model="parroqui" name="parroqui" id="parroqui" :disabled="this.city == ''" required
                    placeholder="item.placeholder">

                    <option value="" selected disabled>Seleccione Parroquia</option>
                    <option v-for=" item in this.parroquis" v-bind:key="item" :value="item">{{ item }}
                    </option>

                </select>
            </div>

            <div style="width: 95%" class="param-data-personal">
                <label>Busque la ubicación de su domicilio</label>
                <div>
                    <GmapAutocomplete @place_changed='setPlace' />
                </div>
                <div id="map">
                    <GmapMap :center="center" :zoom="mapZoom" style="width: 100%; height: 400px">
                        <GmapMarker :position="center" @drag="coordStreetGoogle" :clickable="true" :draggable="true" />
                    </GmapMap>
                </div>
            </div>
            <br />
            <br />
            <div class="param-data-personal">
                <label>Número de Casa</label>
                <input v-model="nro_house_user" type="text" name="nro_house_user" id="nro_house_user" required
                    placeholder="Ejm 608-44" />
            </div>
            <div class="param-data-personal">
                <label>Referencia</label>
                <!-- @keydown="sololetras(event);"-->
                <input v-model="reference_house_user" type="text" name="reference_house_user" maxlength="30"
                    id="reference_house_user" required placeholder="Ejm. Frente al parque principal" />
            </div>

            <br />
            <br />

            <p>
                Al continuar, estas confirmando que la información proporcionada es
                <strong>correcta, veridica, comprobable y autorizas a {{ this.title }}
                    Ecuador</strong>
                a enviar a tu teléfono celular y/o correo electrónico, los codigos
                correspondes para validar el ingreso y aceptación de condiciones.
            </p>
            <br />
            <br />

            <input hidden type="submit" id="firstform" />
        </form>
    </div>
</template>


<style scoped>
#data-personal {
    margin-top: 2vw;
}

.param-data-personal select {
    width: 104% !important;
    border-style: solid;
    border-width: 0.15vw;
}

.param-data-personal input:focus {
    outline: 0;
    border-color: var(--colorP);
}

.param-data-personal label {
    font-weight: bold;
}

.param-data-personal {
    display: inline-grid;
    width: 40%;
    margin: 1vw 7vw 0vw 1vw;
}

.param-data-personal input,
select {
    width: 100%;
    margin: 1vw 0.2vw;
    height: 2.5vw;
    border-radius: 6px;
    border-color: #31353552;
    padding: 0px 0px 0px 14px;
    border-style: solid;
    font-size: 1vw;
}

@media screen and (max-width: 768px) {
    .param-data-personal {
        width: 100%;
    }

    .param-data-personal input,
    select {
        height: 45px;
        border-radius: 8px;
        margin-bottom: 4vw;
        font-size: 4vw;

    }
}
</style>

/*
    APIS[0] = Api para obtener todas 
    APIS[1] = Api para obtener datos 

*/
//const api = "https://www.fintech.kradac.com:3008/";
const api = process.env.VUE_APP_SERVIDOR
// const api = "https://www.fintech.kradac.com:8443/";  
export const APIS = [
    {
        name: api + "dynamicform",
        metodo: "POST",
        descripcion: "Api para obtener campos de form dependiente de la entidad financiera"
    },
    {
        name: api + "entity",
        metodo: "POST",
        descripcion: "Api para obtener caracteristicas de la Entidad Financiera"
    },
    {
        name: api + "terms/only_entity",
        metodo: "POST",
        descripcion: "Api para obtener Terminos y condiciones"
    },
    {
        name: api + "civil_registry/validate",
        metodo: "POST",
        descripcion: "Api para obtener datos del registro civil"
    },
    {
        name: api + "recognition/comparate",
        metodo: "POST",
        descripcion: "Api para obtener comparacion de la cedula y selfie"
    },
    {
        name: api + "sendregister",
        metodo: "POST",
        descripcion: "Api para guardar el registro del usuario"
    },
    {
        name: api + "code/send",
        metodo: "POST",
        descripcion: "Api para enviar codigo de confirmación"
    },
    {
        name: api + "code/check",
        metodo: "POST",
        descripcion: "Api para verificar codigo de confirmación ingresado por el usuario"
    },
    {
        name: api + "monitoreo/find_total_caller_apis",
        metodo: "POST",
        descripcion: "Monitoreo - Busca en que paso se quedaron más usuarios"
    },
    {
        name: api + "monitoreo/users_not_finished",
        metodo: "POST",
        descripcion: "Monitoreo - Busca la lista de usuarios que no completaron el proceso y en que paso se quedaron"
    },
    {
        name: api + "monitoreo/find_clients_entity",
        metodo: "POST",
        descripcion: "Monitoreo - Busca la lista de usuarios que no completaron el proceso y en que paso se quedaron"
    },
    {
        name: api + "monitoreo/changeStatus",
        metodo: "POST",
        descripcion: "Monitoreo - Busca la lista de usuarios que no completaron el proceso y en que paso se quedaron"
    }
]
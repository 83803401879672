<script>
import LogoSelfie from "../assets/icons/selfie.png";

import $ from "jquery";
export default {
    components: {

    },
    data() {
        return {
            msjdos: "Click aquí para capturar una selfie con tu dispositivo",
            card2: true,
            estado: "",
            action2: 1,
            img2: LogoSelfie,
            imagenSelfie: null,
            msgModal: "Datos incorrectos intente de nuevo",
            dni: this.$session.get("dni"),
            civilPhoto: this.$session.get("civilPhoto"),
            email_user: this.$session.get("email"),
            time: true,
        }
    },
    props: ["APIS", "header"],
    beforeMount() {
    },
    mounted: function () {
        console.log(this.header)
    },
    methods: {
        closeModal() {
            this.$modal.hide("modalFace");
        },
        getImage(event) {
            this.imagenCedula == null
                ? (this.imagenCedula = event.target.files[0])
                : (this.imagenSelfie = event.target.files[0]);
            this.card1 = false;
            this.card2 = false;
            var reader = new FileReader();
            reader.onload = function (e) {
                $("#vistaprevia").attr("src", e.target.result);
            };
            reader.readAsDataURL(event.target.files[0]);

            this.estado = "avatar";
        },
        subir: function (variable) {
            const video = document.getElementById("video");
            console.log('tenemos elemento id de vieoo ', video)
            switch (variable) {
                case 0:
                    video.srcObject.getTracks()[0].stop();
                    $("#msjSonrisa2").html("Sonría para tomar la foto");
                    this.card2 = true
                    this.estado = "";
                    this.selfieError = false;
                    break;
                case 1:
                    this.card1 = false;
                    this.card2 = false;
                    this.estado = "avatarvideo";
                    $("#ver").click();
                    break;
            }
        },
        getSelfie() {
            if (this.time) {
                this.time = false;
                var canvas = document.getElementById("photoBio");
                const dataURL = canvas.toDataURL();
                var strImage = dataURL.replace(/^data:image\/[a-z]+;base64,/, "");
                const video = document.getElementById("video");
                video.srcObject.getTracks()[0].stop();
                $("#msjSonrisa").html("Identicando su rostro....");
                const headers = {
                    lat: this.header.latitud,
                    long: this.header.longitud,
                    Authorization: "Bearer " + this.header.token,
                };

                const body = {
                    id_entity: this.header.idEntity,
                    dni: this.dni,
                    selfie_base64: strImage,
                    photo_base64: this.civilPhoto,
                    email_user: this.email_user,
                };

                this.$http
                    .post(this.APIS[4].name, body, { headers })
                    .then((response) => {
                        if (response.status == 200) {
                            this.time = true
                            if (response.data.confidence > 70 && parseFloat(response.data.livenessPerson.doc_json.real) > 0.80) {
                                this.imagenSelfie = strImage;
                                this.$session.set("imagenSelfie", strImage);
                                this.modalmessage = "Reconocimiento Satisfactorio ";
                                this.msgModal =
                                    "Reconocimiento Satisfactorio"
                                this.$modal.show("modalFace");
                                this.$emit("successApi");
                            } else {
                                this.subir(0)
                                this.msgModal =
                                    "Datos incorrectos intente de nuevo "
                                this.$modal.show("modalFace");
                            }
                        } else {
                            this.time = false
                            this.imagenSelfie = null;
                            this.msgModal =
                                "Datos incorrectos intente de nuevo ";
                            this.$modal.show("modalFace");
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        this.$modal.show("modalFace");
                    });
            }

        },

    }
};
</script>
<template>
    <div class="container-options">
        <div v-show="this.card2" class="card">
            <img class="img-options" v-on:click="subir(action2)" :src="img2" />
            <p>{{ msjdos }}</p>
        </div>

        <div v-show="this.estado == 'avatar'" class="card">
            <img class="img-options" /><img class="vistaprevia" id="vistaprevia" />
        </div>

        <div v-show="this.estado == 'avatarvideo'" style="display: block" class="card">
            <p id="msjSonrisa2" style="margin: 2vw auto; height: 10px; padding: 0px">
                Sonría para tomar la foto
            </p>
            <div class="cardvideo">
                <div v-show="this.imagenSelfie == null" class="video-wrap">
                    <video id="video" muted autoplay></video>
                </div>
                <canvas style="display: none" id="photoBio" width="640" height="480"></canvas>
            </div>
            <div class="controllervideo">
                <button hidden id="ver">Volver a tomar la Foto</button>
                <p id="msjSonrisa">Sonrisa no detectada</p>
                <br />
                <button hidden id="btnCaptureHidden">Capture</button>
                <canvas id="canvas" style="display: none" width="320" height="350"></canvas>
                <button @click="getSelfie" hidden id="capturar">
                    Capturar Selfie
                </button>
            </div>
        </div>
        <input hidden id="avatar" type="file" name="image" @change="getImage" accept="image/*" />


        <modal id="modalFace" name="modalFace" :draggable="false" :scrollable="false" width="40%" height="auto"
            :resizable="false" :adaptive="false">
            <center class="modal-alert">
                {{ msgModal }}
                <input @click="closeModal" class="form-submit alert" value="Entendido" />
            </center>
        </modal>
    </div>
</template>


<style scoped>
.img-options {
    width: 10vw;
    margin: auto;
}

.cardvideo {
    display: flex;
}

.controllervideo {
    display: flex;
    width: 100%;
}

.controllervideo button {
    margin: auto;
}

.video-wrap video {
    width: 100%;
    height: 100%;
}

.video-wrap {
    margin: auto 3.5vw;
}

.vistaprevia {
    width: 18vw;
    margin: auto;
}

.modal-alert {
    color: var(--colorP);
    font-size: 1.5vw;
    padding: 3vw 1vw;
}

.alert {
    width: 32%;
    text-align: center;
    margin-top: 1vw;
}

@media screen and (max-width: 768px) {
    .vistaprevia {
        width: 36vw;
    }

    .img-options {
        width: 33vw;
    }

    .video-wrap video {
        display: flex;
        width: 63% !important;
        margin: auto;
    }

    .video-wrap {
        margin: auto auto !important;
    }

    .controllervideo {
        width: 100% !important;
    }
}
</style>

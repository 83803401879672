<script>
export default {
    props: ['step'],
};
</script>
<template>
    <div id="navs" class="content-steps">
        <hr class="linestep" />
        <div style="margin: auto" v-bind:class="[step >= 1 ? 'activate' : 'desactive']">
            <circule>
                <number>
                    <pc>1</pc>
                </number>
            </circule>
            <ps>Identificación</ps>
        </div>
        <div style="margin: auto" v-bind:class="[step >= 2 ? 'activate' : 'desactive']">
            <circule>
                <number>
                    <pc>2</pc>
                </number>
            </circule>
            <ps>Datos personales</ps>
        </div>
        <div style="margin: auto" v-bind:class="[step >= 3 ? 'activate' : 'desactive']">
            <circule>
                <number>
                    <pc>3</pc>
                </number>
            </circule>
            <ps>Profesión</ps>
        </div>
        <div style="margin: auto" v-bind:class="[step >= 4 ? 'activate' : 'desactive']">
            <circule>
                <number>
                    <pc>4</pc>
                </number>
            </circule>
            <ps>Términos y Condiciones</ps>
        </div>
        <div style="margin: auto" v-bind:class="[step >= 5 ? 'activate' : 'desactive']">
            <circule>
                <number>
                    <pc>5</pc>
                </number>
            </circule>
            <ps>Confirmación</ps>
        </div>
    </div>
</template>


<style scoped>
.content-steps {
    margin: 2vw auto;
    width: 61%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
}

.linestep {
    height: 1px;
    background-color: #00000070;
    width: 49vw;
    margin: 1.2vw 0vw 1vw 6vw;
    position: absolute;
}

.activate circule>number>pc {
    color: white;
}

.desactive circule {
    background: white;
}

.desactive>number,
ps,
pc {
    color: #b5b5b5;
}

.activate ps {
    color: black;
}

circule {
    background: var(--colorP);
    display: flex;
    width: 2.5vw;
    height: 2.5vw;
    border-radius: 3vw;
    margin: auto;
    border: solid;
    border-width: thin;
    border-color: #00000047;
    margin-bottom: 1vw;
    position: sticky;
}

number {
    margin: auto;
    color: white;
}

pc {
    font-size: 1.5vw;
    font-weight: 700;
}

ps {
    font-size: 1.2vw;
    font-weight: 700;
}

@media screen and (max-width: 768px) {
    circule {
        width: 8vw !important;
        height: 8vw !important;
        border-radius: 5vw !important;
    }

    .content-steps {
        width: 100% !important;
    }

    .linestep {
        width: 87vw !important;
        margin: 4vw 0vw 1vw 6vw !important;
    }

    pc {
        font-size: 4.5vw !important;
    }

    ps {
        font-size: 2.2vw !important;
    }
}
</style>

<script>
import $ from "jquery";
export default {
    props: ['header', 'APIS', 'form', 'telefono', 'dni', 'email'],
    data: function () {
        return {
            coordinates: [-78.183406, -1.831239],
            center: { lat: 39.7837304, lng: -100.4458825 },
        }
    },
    methods: {
        openModal: function (id) {
            var newloc = id;
            window.open(newloc, "TERMINOS", "width=800, height=800");
        },
        submitTerms: function () {
            console.log(
                this.telefono,
                this.dni,
                this.email
            )
            const formData = new FormData(this.$refs["data-terms"]); // reference to form element
            const data = {}; // need to convert it before using not with XMLHttpRequest
            for (let [key, val] of formData.entries()) {
                Object.assign(data, { [key]: val });
            }
            let tlf_user = this.telefono;
            if (tlf_user.length == 10) {
                tlf_user = this.telefono.substr(1);
            }
            const headers = {
                lat: this.header.latitud,
                long: this.header.longitud,
                Authorization: "Bearer " + this.header.token,
            };
            const body = {
                id_entity: this.header.idEntity,
                email_user: this.email,
                dni: this.dni,
                tlf_user: "593" + tlf_user,
                transaction_type: "Apertura de cuenta",
            };

            this.$http
                .post(this.APIS[6].name, body, { headers })
                .then((response) => {
                    if (response.status == 200) {
                        this.$emit("successApi", data);
                        $("html, body").animate(
                            { scrollTop: $("#navs").offset().top },
                            2000
                        );

                    } else {
                        this.$modal.show("modalError");
                    }
                })
                .catch((error) => {
                    if (error) {
                        this.$modal.show("modalError");
                    }
                });
        },
    }
};
</script>
<template>
    <div class="container-data-personal">
        <form class="threeform" ref="terms" id="data-terms" @submit.prevent="submitTerms">
            <div v-for="item in form" v-bind:key="item.id">
                <input type="checkbox" required :id="item.id_entidad" :name="item.id_entidad" />
                <label>
                    He leído y aceptado los
                    <a style="cursor: pointer" v-on:click="openModal(item.descripcion)">
                        <strong>{{ item.titulo }}</strong>
                    </a>
                </label>

                <modal width="40%" height="300" style="overflow: auto" draggable=".window-header" :adaptive="true"
                    class="modal" :id="item.id_entidad" :name="item.titulo">
                    <p>{{ item.descripcion }}</p>
                </modal>
            </div>

            <input hidden type="submit" id="threeform" />
        </form>
    </div>
</template>


<style scoped>
#data-personal {
    margin-top: 2vw;
}

.threeform label {
    display: flex;
    margin-left: 2vw;
    margin-bottom: 2vw;
    margin-top: 3vw;
    font-size: 1.2vw;
}

.threeform input {
    float: left;
    margin: auto 2vw;
    width: 3vw;
    height: 2vw;
}

.threeform p {
    cursor: pointer;
    font-weight: 200;
}


@media screen and (max-width: 768px) {
    .threeform label {
        font-size: 4vw;
    }

    .threeform input {
        margin: 2vw 4vw;
        width: 6vw;
        height: 6vw;
    }

    .threeform {
        margin: 13vw 0vw;
    }
}
</style>
